export const USER_ROLE = {
  PRINCIPAL: 'SCHOOL.PRINCIPAL'
}

export const USER_ACCESS_TYPE = {
  OES_SUPPORT: 'OESSupport'
}

export const RADIO_OPTIONS = {
  YES: { label: 'Yes', value: 'Y' },
  NO: { label: 'No', value: 'N' },
  NOT_REQUIRED: { label: 'Not required', value: 'N' }
}

export const YES_NO = [RADIO_OPTIONS.YES, RADIO_OPTIONS.NO]
export const YES_NOT_REQUIRED = [RADIO_OPTIONS.YES, RADIO_OPTIONS.NOT_REQUIRED]

export const SCHOLASTIC_YEAR_KINDERGARTEN = 'K'

export const SCHOLASTIC_YEARS = {
  [SCHOLASTIC_YEAR_KINDERGARTEN]: 'Kindergarten',
  [1]: 'Year 1',
  [2]: 'Year 2',
  [3]: 'Year 3',
  [4]: 'Year 4',
  [5]: 'Year 5',
  [6]: 'Year 6',
  [7]: 'Year 7',
  [8]: 'Year 8',
  [9]: 'Year 9',
  [10]: 'Year 10',
  [11]: 'Year 11',
  [12]: 'Year 12'
}

//Values to set fields to if they are hidden on save/accept/decline (before beign sent to ERN)
export const HIDDEN_FIELD_DEFAULTS = {
  riskAssessmentRequired: RADIO_OPTIONS.NO.value,
  riskAssessmentConducted: RADIO_OPTIONS.NO.value,
  personalisedLearningSupport: RADIO_OPTIONS.NO.value,
  consultWithParentsConducted: RADIO_OPTIONS.NO.value,
  planningPersonalisedLearningSupport: RADIO_OPTIONS.NOT_REQUIRED.value,
  behaviourPlanViolenceDeveloped: RADIO_OPTIONS.NOT_REQUIRED.value,
  behaviourPlanOtherDeveloped: RADIO_OPTIONS.NOT_REQUIRED.value,
  healthCarePlanDeveloped: RADIO_OPTIONS.NOT_REQUIRED.value,
  emergencyRespPlanDeveloped: RADIO_OPTIONS.NOT_REQUIRED.value
}

export const CERTIFICATION_STATUS = {
  READY: 'READY',
  IN_PROGRESS: 'IN PROGRESS',
  NEW: 'NEW',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  WITHDRAWN: 'WITHDRAWN'
}

export const CERTIFICATION_STATUS_OPTIONS = [
  { label: 'New', value: CERTIFICATION_STATUS.NEW },
  { label: 'In Progress', value: CERTIFICATION_STATUS.IN_PROGRESS },
  { label: 'Ready', value: CERTIFICATION_STATUS.READY },
  { label: 'Accepted', value: CERTIFICATION_STATUS.ACCEPTED },
  { label: 'Declined', value: CERTIFICATION_STATUS.DECLINED },
  { label: 'Withdrawn', value: CERTIFICATION_STATUS.WITHDRAWN }
]

export const CERTIFICATION_STATUS_ORDER = [
  CERTIFICATION_STATUS.NEW,
  CERTIFICATION_STATUS.IN_PROGRESS,
  CERTIFICATION_STATUS.READY,
  CERTIFICATION_STATUS.ACCEPTED,
  CERTIFICATION_STATUS.DECLINED,
  CERTIFICATION_STATUS.WITHDRAWN
]

export const API_ERROR_SUBTYPES = {
  certificationDetails: 'PC-RET',
  saveCertificationDetails: 'PC-SAV',
  saveCertificationConflict: 'PC-SAV-CONFLICT',
  acceptCertification: 'PC-ACC',
  acceptCertificationConflict: 'PC-ACC-CONFLICT',
  declineCertification: 'PC-DEC',
  declineCertificationConflict: 'PC-DEC-CONFLICT',
  listCertification: 'PC-LST',
  getSchoolCustomisationSettings: 'PS-GET-SETTING',
  saveSchoolCustomisationSettings: 'PS-SAVE-SETTING',
  ernStudentInfo: 'PC-STU'
}

export const ERROR_CODES = {
  API: 'API',
  USER_ERROR: 'USER_ERROR'
}

export const NODE_ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PREPROD: 'PRE',
  PROD: 'PROD',
  TRAIN: 'TRAIN'
}

export const EMAIL_SETTING_PREFERENCES_OPTIONS = [
  {
    label: 'Yes, we want to receive a daily summary email',
    value: true
  },
  {
    label: 'No, we don’t want to receive a daily summary email',
    value: false
  }
]

export const DISCARD_MODAL_OPTIONS = {
  CANCEL: 'Cancel',
  DISCARD_CHANGES: 'Discard changes'
}

export const SCHOOL_TYPE = [
  { text: 'Australian school', value: 'A' },
  { text: 'Overseas school', value: 'O' },
  { text: 'Other', value: 'Y' },
  { text: 'Unknown/Not Provided', value: 'X' }
]

export const GENDER_CODES = [
  { text: 'Male', value: 'M' },
  { text: 'Female', value: 'F' }
]

export const RELATIONSHIPS = [
  {
    text: 'Mother',
    value: 'MUM'
  },
  {
    text: 'Father',
    value: 'DAD'
  },
  {
    text: 'Grandmother',
    value: 'GMO'
  },
  {
    text: 'Grandfather',
    value: 'GFA'
  },
  {
    text: 'Grandfather / Grandmother',
    value: 'GFM'
  },
  {
    text: 'Step Mother',
    value: 'SMO'
  },
  {
    text: 'Step Father',
    value: 'SFA'
  },
  {
    text: 'Sister',
    value: 'SIS'
  },
  {
    text: 'Brother',
    value: 'BRO'
  },
  {
    text: 'The Student (herself)',
    value: 'STUF'
  },
  {
    text: 'The Student (himself)',
    value: 'STUM'
  },
  {
    text: 'Aunt',
    value: 'AUN'
  },
  {
    text: 'Aunt / Uncle',
    value: 'AUUN'
  },
  {
    text: 'Caseworker',
    value: 'CASE'
  },
  {
    text: 'Friend',
    value: 'FRI'
  },
  {
    text: 'Godfather',
    value: 'DON'
  },
  {
    text: 'Godmother',
    value: 'GDM'
  },
  {
    text: 'Guardian (organisation)',
    value: 'ORG'
  },
  {
    text: 'Guardian Father (Organisation)',
    value: 'GUF'
  },
  {
    text: 'Guardian Homestay (Organisation)',
    value: 'GHS'
  },
  {
    text: 'Guardian Mother  (Organisation)',
    value: 'GUM'
  },
  {
    text: 'Guardian/ Defacto (Female)',
    value: 'GDF'
  },
  {
    text: 'Guardian/ Defacto (Male)',
    value: 'GDA'
  },
  {
    text: 'Nanny',
    value: 'NAN'
  },
  {
    text: 'Neighbour',
    value: 'NEI'
  },
  {
    text: 'Other relative',
    value: 'OTR'
  },
  {
    text: 'Uncle',
    value: 'UNC'
  },
  {
    text: 'Other (Not elsewhere classified)',
    value: 'YYY'
  }
]

export const TITLES = [
  {
    text: 'Mr',
    value: 'Mr'
  },
  {
    text: 'Mrs',
    value: 'Mrs'
  },
  {
    text: 'Ms',
    value: 'Ms'
  },
  {
    text: 'Mr and Mrs',
    value: 'Mr & Mrs'
  },
  {
    text: 'Mx',
    value: 'Mx'
  },
  {
    text: 'Associate Professor',
    value: 'Ass Prof'
  },
  {
    text: 'Brother',
    value: 'Br'
  },
  {
    text: 'Doctor',
    value: 'Dr'
  },
  {
    text: 'Father',
    value: 'Fr'
  },
  {
    text: 'Honourable',
    value: 'Hon'
  },
  {
    text: 'Lady',
    value: 'Lady'
  },
  {
    text: 'Lord',
    value: 'Lord'
  },
  {
    text: 'Madam',
    value: 'Mdm'
  },
  {
    text: 'Master',
    value: 'Mstr'
  },
  {
    text: 'Miss',
    value: 'Miss'
  },
  {
    text: 'Professor',
    value: 'Prof'
  },
  {
    text: 'Rabbi',
    value: 'Rabbi'
  },
  {
    text: 'Reverend',
    value: 'Rev'
  },
  {
    text: 'Sir',
    value: 'Sir'
  },
  {
    text: 'Sister',
    value: 'Sr'
  }
]

export const ABORIGINALITY_CODES = [
  {
    code: '4',
    value: 'No',
    active: 'A'
  },
  {
    code: '1',
    value: 'Yes, Aboriginal',
    active: 'A'
  },
  {
    code: '2',
    value: 'Yes, Torres Strait Islander',
    active: 'A'
  },
  {
    code: '3',
    value: 'Yes, both Aboriginal and Torres Strait Islander',
    active: 'A'
  }
]

export const STATEMENT_OF_ACCOUNT_TYPES = [
  {
    code: 'FIR',
    value:
      'Financially Responsible - receives the original Statement of Account',
    active: 'A'
  },
  {
    code: 'FII',
    value:
      'Financially Interested - receives a copy of the Statement of Account',
    active: 'A'
  },
  {
    code: 'FNO',
    value: 'No - does not receive the Statement of Account',
    active: 'A'
  }
]

export const CONTACT_TYPES = [
  { code: 'PHDAY', value: 'Daytime Phone Number', active: 'A' },
  { code: 'PHMOB', value: 'Phone (Mobile)', active: 'A' },
  { code: 'PHTXT', value: 'Text Message (SMS) details', active: 'A' },
  { code: 'PHHOM', value: 'Phone (Home)', active: 'A' },
  { code: 'PHWRK', value: 'Phone (Business/Work)', active: 'A' },
  { code: 'PHSIL', value: 'Silent Phone Number', active: 'A' },
  { code: 'EMAIL', value: 'E-Mail', active: 'A' },
  { code: 'FXHOM', value: 'Fax (Home)', active: 'A' },
  { code: 'FXWRK', value: 'Fax (Business/Work)', active: 'A' },
  { code: 'WEB', value: 'Website', active: 'A' },
  { code: 'WWW', value: 'Not Applicable', active: 'S' },
  { code: 'YYY', value: 'Other (Not elswhere classified)', active: 'S' },
  { code: 'SCASTO', value: 'SCAS Email', active: 'S' },
  { code: 'XXX', value: 'Unknown/Not Provided', active: 'S' },
  { code: 'AREM', value: 'AR Email', active: 'S' },
  { code: 'ZZZ', value: 'Not Stated (intentionally)', active: 'S' },
  { code: 'OOO', value: 'OASIS Data Conversion', active: 'S' }
]

export const COUNTRIES = [
  { code: 'ADF', value: 'Adélie Land (France)', active: 'A' },
  { code: 'AFG', value: 'Afghanistan', active: 'A' },
  { code: 'ALI', value: 'Aland Islands', active: 'A' },
  { code: 'ALB', value: 'Albania', active: 'A' },
  { code: 'DZA', value: 'Algeria', active: 'A' },
  { code: 'AND', value: 'Andorra', active: 'A' },
  { code: 'AGO', value: 'Angola', active: 'A' },
  { code: 'AIA', value: 'Anguilla', active: 'A' },
  { code: 'ATG', value: 'Antigua and Barbuda', active: 'A' },
  { code: 'ARG', value: 'Argentina', active: 'A' },
  { code: 'AAT', value: 'Argentinian Antarctic Territory', active: 'A' },
  { code: 'ARM', value: 'Armenia', active: 'A' },
  { code: 'ABW', value: 'Aruba', active: 'A' },
  { code: 'AUS', value: 'Australia', active: 'A' },
  {
    code: 'AUE',
    value: 'Australia (includes External Territories)',
    active: 'A'
  },
  { code: 'AST', value: 'Australian Antarctic Territory', active: 'A' },
  {
    code: 'AET',
    value: 'Australian External Territories nec',
    active: 'A'
  },
  { code: 'AUT', value: 'Austria', active: 'A' },
  { code: 'AZE', value: 'Azerbaijan', active: 'A' },
  { code: 'BHS', value: 'Bahamas', active: 'A' },
  { code: 'BHR', value: 'Bahrain', active: 'A' },
  { code: 'BGD', value: 'Bangladesh', active: 'A' },
  { code: 'BRB', value: 'Barbados', active: 'A' },
  { code: 'BLR', value: 'Belarus', active: 'A' },
  { code: 'BEL', value: 'Belgium', active: 'A' },
  { code: 'BLZ', value: 'Belize', active: 'A' },
  { code: 'BEN', value: 'Benin', active: 'A' },
  { code: 'BMU', value: 'Bermuda', active: 'A' },
  { code: 'BTN', value: 'Bhutan', active: 'A' },
  { code: 'BOL', value: 'Bolivia', active: 'A' },
  { code: 'BIH', value: 'Bosnia and Herzegovina', active: 'A' },
  { code: 'BWA', value: 'Botswana', active: 'A' },
  { code: 'BRA', value: 'Brazil', active: 'A' },
  { code: 'BAT', value: 'British Antarctic Territory', active: 'A' },
  { code: 'BRN', value: 'Brunei Darussalam', active: 'A' },
  { code: 'BGR', value: 'Bulgaria', active: 'A' },
  { code: 'BFA', value: 'Burkina Faso', active: 'A' },
  { code: 'MMR', value: 'Burma (Myanmar)', active: 'A' },
  { code: 'BDI', value: 'Burundi', active: 'A' },
  { code: 'KHM', value: 'Cambodia', active: 'A' },
  { code: 'CMR', value: 'Cameroon', active: 'A' },
  { code: 'CAN', value: 'Canada', active: 'A' },
  { code: 'CPV', value: 'Cape Verde', active: 'A' },
  { code: 'CYM', value: 'Cayman Islands', active: 'A' },
  { code: 'CAF', value: 'Central African Republic', active: 'A' },
  { code: 'TCD', value: 'Chad', active: 'A' },
  { code: 'CHL', value: 'Chile', active: 'A' },
  { code: 'CAT', value: 'Chilean Antarctic Territory', active: 'A' },
  {
    code: 'CHN',
    value: 'China (excludes SARs and Taiwan Province)',
    active: 'A'
  },
  { code: 'COL', value: 'Colombia', active: 'A' },
  { code: 'COM', value: 'Comoros', active: 'A' },
  { code: 'COG', value: 'Congo', active: 'A' },
  {
    code: 'COD',
    value: 'Congo, The Democratic Republic of the',
    active: 'A'
  },
  { code: 'COK', value: 'Cook Islands', active: 'A' },
  { code: 'CRI', value: 'Costa Rica', active: 'A' },
  { code: 'CIV', value: "Cote d'Ivoire", active: 'A' },
  { code: 'HRV', value: 'Croatia', active: 'A' },
  { code: 'CUB', value: 'Cuba', active: 'A' },
  { code: 'CYP', value: 'Cyprus', active: 'A' },
  { code: 'CZE', value: 'Czech Republic', active: 'A' },
  { code: 'DNK', value: 'Denmark', active: 'A' },
  { code: 'DJI', value: 'Djibouti', active: 'A' },
  { code: 'DMA', value: 'Dominica', active: 'A' },
  { code: 'DOM', value: 'Dominican Republic', active: 'A' },
  { code: 'TLS', value: 'East Timor', active: 'A' },
  { code: 'ECU', value: 'Ecuador', active: 'A' },
  { code: 'EGY', value: 'Egypt', active: 'A' },
  { code: 'SLV', value: 'El Salvador', active: 'A' },
  { code: 'ENG', value: 'England', active: 'A' },
  { code: 'GNQ', value: 'Equatorial Guinea', active: 'A' },
  { code: 'ERI', value: 'Eritrea', active: 'A' },
  { code: 'EST', value: 'Estonia', active: 'A' },
  { code: 'ETH', value: 'Ethiopia', active: 'A' },
  { code: 'FRO', value: 'Faeroe (Faroe) Islands', active: 'A' },
  { code: 'FLK', value: 'Falkland Islands', active: 'A' },
  { code: 'FJI', value: 'Fiji', active: 'A' },
  { code: 'FIN', value: 'Finland', active: 'A' },
  { code: 'FRA', value: 'France', active: 'A' },
  { code: 'GUF', value: 'French Guiana', active: 'A' },
  { code: 'PYF', value: 'French Polynesia', active: 'A' },
  { code: 'GAB', value: 'Gabon', active: 'A' },
  { code: 'GMB', value: 'Gambia', active: 'A' },
  { code: 'GEO', value: 'Georgia', active: 'A' },
  { code: 'DEU', value: 'Germany', active: 'A' },
  { code: 'GHA', value: 'Ghana', active: 'A' },
  { code: 'GIB', value: 'Gibraltar', active: 'A' },
  { code: 'GRC', value: 'Greece', active: 'A' },
  { code: 'GRL', value: 'Greenland', active: 'A' },
  { code: 'GRD', value: 'Grenada', active: 'A' },
  { code: 'GLP', value: 'Guadeloupe', active: 'A' },
  { code: 'GUM', value: 'Guam', active: 'A' },
  { code: 'GTM', value: 'Guatemala', active: 'A' },
  { code: 'GUE', value: 'Guernsey', active: 'A' },
  { code: 'GIN', value: 'Guinea', active: 'A' },
  { code: 'GNB', value: 'Guinea-Bissau', active: 'A' },
  { code: 'GUY', value: 'Guyana', active: 'A' },
  { code: 'HTI', value: 'Haiti', active: 'A' },
  { code: 'VAT', value: 'Holy See (Vatican City State)', active: 'A' },
  { code: 'HND', value: 'Honduras', active: 'A' },
  { code: 'HKG', value: 'Hong Kong (SAR of China)', active: 'A' },
  { code: 'HUN', value: 'Hungary', active: 'A' },
  { code: 'ISL', value: 'Iceland', active: 'A' },
  { code: 'IND', value: 'India', active: 'A' },
  { code: 'IDN', value: 'Indonesia', active: 'A' },
  { code: 'IRN', value: 'Iran, Islamic Republic of', active: 'A' },
  { code: 'IRQ', value: 'Iraq', active: 'A' },
  { code: 'IRL', value: 'Ireland (Republic Of)', active: 'A' },
  { code: 'IMN', value: 'Isle of Man', active: 'A' },
  { code: 'ISR', value: 'Israel', active: 'A' },
  { code: 'ITA', value: 'Italy', active: 'A' },
  { code: 'JAM', value: 'Jamaica', active: 'A' },
  { code: 'JPN', value: 'Japan', active: 'A' },
  { code: 'JER', value: 'Jersey', active: 'A' },
  { code: 'JOR', value: 'Jordan', active: 'A' },
  { code: 'KAZ', value: 'Kazakhstan', active: 'A' },
  { code: 'KEN', value: 'Kenya', active: 'A' },
  { code: 'KIR', value: 'Kiribati', active: 'A' },
  {
    code: 'PRK',
    value: "Korea, Democratic People's Republic of (North)",
    active: 'A'
  },
  { code: 'KOR', value: 'Korea, Republic of (South)', active: 'A' },
  { code: 'KOS', value: 'Kosovo', active: 'A' },
  { code: 'KWT', value: 'Kuwait', active: 'A' },
  { code: 'KGZ', value: 'Kyrgyz Republic (Kyrgystan)', active: 'A' },
  {
    code: 'LAO',
    value: "Laos (Lao People's Democratic Republic)",
    active: 'A'
  },
  { code: 'LVA', value: 'Latvia', active: 'A' },
  { code: 'LBN', value: 'Lebanon', active: 'A' },
  { code: 'LSO', value: 'Lesotho', active: 'A' },
  { code: 'LBR', value: 'Liberia', active: 'A' },
  { code: 'LBY', value: 'Libya (Libyan Arab Jamahiriya)', active: 'A' },
  { code: 'LIE', value: 'Liechtenstein', active: 'A' },
  { code: 'LTU', value: 'Lithuania', active: 'A' },
  { code: 'LUX', value: 'Luxembourg', active: 'A' },
  { code: 'MAC', value: 'Macau (SAR of China)', active: 'A' },
  {
    code: 'MKD',
    value: 'Macedonia, The former Yugoslav Republic of',
    active: 'A'
  },
  { code: 'MDG', value: 'Madagascar', active: 'A' },
  { code: 'MWI', value: 'Malawi', active: 'A' },
  { code: 'MYS', value: 'Malaysia', active: 'A' },
  { code: 'MDV', value: 'Maldives', active: 'A' },
  { code: 'MLI', value: 'Mali', active: 'A' },
  { code: 'MLT', value: 'Malta', active: 'A' },
  { code: 'MHL', value: 'Marshall Islands', active: 'A' },
  { code: 'MTQ', value: 'Martinique', active: 'A' },
  { code: 'MRT', value: 'Mauritania', active: 'A' },
  { code: 'MUS', value: 'Mauritius', active: 'A' },
  { code: 'MYT', value: 'Mayotte', active: 'A' },
  { code: 'MEX', value: 'Mexico', active: 'A' },
  { code: 'FSM', value: 'Micronesia, Federated States of', active: 'A' },
  { code: 'MDA', value: 'Moldova, Republic Of', active: 'A' },
  { code: 'MCO', value: 'Monaco', active: 'A' },
  { code: 'MNG', value: 'Mongolia', active: 'A' },
  { code: 'MON', value: 'Montenegro', active: 'A' },
  { code: 'MSR', value: 'Montserrat', active: 'A' },
  { code: 'MAR', value: 'Morocco', active: 'A' },
  { code: 'MOZ', value: 'Mozambique', active: 'A' },
  { code: 'NAM', value: 'Namibia', active: 'A' },
  { code: 'NRU', value: 'Nauru', active: 'A' },
  { code: 'NPL', value: 'Nepal', active: 'A' },
  { code: 'NLD', value: 'Netherlands', active: 'A' },
  { code: 'ANT', value: 'Netherlands Antilles', active: 'A' },
  { code: 'NCL', value: 'New Caledonia', active: 'A' },
  { code: 'NZL', value: 'New Zealand', active: 'A' },
  { code: 'NIC', value: 'Nicaragua', active: 'A' },
  { code: 'NER', value: 'Niger', active: 'A' },
  { code: 'NGA', value: 'Nigeria', active: 'A' },
  { code: 'NIU', value: 'Niue', active: 'A' },
  { code: 'NFK', value: 'Norfolk Island', active: 'A' },
  { code: 'NIR', value: 'Northern Ireland', active: 'A' },
  { code: 'MNP', value: 'Northern Mariana Islands', active: 'A' },
  { code: 'NOR', value: 'Norway', active: 'A' },
  { code: 'OMN', value: 'Oman', active: 'A' },
  { code: 'PAK', value: 'Pakistan', active: 'A' },
  { code: 'PLW', value: 'Palau', active: 'A' },
  {
    code: 'PSE',
    value: 'Palestine (Gaza Strip and West Bank)',
    active: 'A'
  },
  { code: 'PAN', value: 'Panama', active: 'A' },
  { code: 'PNG', value: 'Papua New Guinea', active: 'A' },
  { code: 'PRY', value: 'Paraguay', active: 'A' },
  { code: 'PER', value: 'Peru', active: 'A' },
  { code: 'PHL', value: 'Philippines', active: 'A' },
  { code: 'PII', value: 'Pitcairn Islands', active: 'A' },
  { code: 'POL', value: 'Poland', active: 'A' },
  { code: 'PHN', value: 'Polynesia (excludes Hawaii), nec', active: 'A' },
  { code: 'PRT', value: 'Portugal', active: 'A' },
  { code: 'PRI', value: 'Puerto Rico', active: 'A' },
  { code: 'QAT', value: 'Qatar', active: 'A' },
  { code: 'QML', value: 'Queen Maud Land (Norway)', active: 'A' },
  { code: 'REU', value: 'Reunion', active: 'A' },
  { code: 'ROU', value: 'Romania', active: 'A' },
  { code: 'RDZ', value: 'Ross Dependency (New Zealand)', active: 'A' },
  { code: 'RUS', value: 'Russian Federation', active: 'A' },
  { code: 'RWA', value: 'Rwanda', active: 'A' },
  { code: 'ASM', value: 'Samoa (American Samoa)', active: 'A' },
  { code: 'WSM', value: 'Samoa (Western)', active: 'A' },
  { code: 'SMR', value: 'San Marino', active: 'A' },
  { code: 'STP', value: 'Sao Tome and Principe', active: 'A' },
  { code: 'SAU', value: 'Saudi Arabia', active: 'A' },
  { code: 'SCO', value: 'Scotland', active: 'A' },
  { code: 'SEN', value: 'Senegal', active: 'A' },
  { code: 'SER', value: 'Serbia', active: 'A' },
  { code: 'SRB', value: 'Serbia and Montenegro', active: 'A' },
  { code: 'SYC', value: 'Seychelles', active: 'A' },
  { code: 'SLE', value: 'Sierra Leone', active: 'A' },
  { code: 'SGP', value: 'Singapore', active: 'A' },
  { code: 'SVK', value: 'Slovakia', active: 'A' },
  { code: 'SVN', value: 'Slovenia', active: 'A' },
  { code: 'SLB', value: 'Solomon Islands', active: 'A' },
  { code: 'SOM', value: 'Somalia', active: 'A' },
  { code: 'ZAF', value: 'South Africa', active: 'A' },
  { code: 'SOA', value: 'South America', active: 'A' },
  { code: 'SAN', value: 'South America, nec', active: 'A' },
  { code: 'SEA', value: 'Southern and East Africa, nec', active: 'A' },
  { code: 'ESP', value: 'Spain', active: 'A' },
  { code: 'SNA', value: 'Spanish North Africa', active: 'A' },
  { code: 'LKA', value: 'Sri Lanka', active: 'A' },
  { code: 'STB', value: 'St Barthelemy', active: 'A' },
  { code: 'SHN', value: 'St Helena', active: 'A' },
  { code: 'KNA', value: 'St Kitts and Nevis', active: 'A' },
  { code: 'LCA', value: 'St Lucia', active: 'A' },
  { code: 'STM', value: 'St Martin (French part)', active: 'A' },
  { code: 'SPM', value: 'St Pierre and Miquelon', active: 'A' },
  { code: 'VCT', value: 'St Vincent and the Grenadines', active: 'A' },
  { code: 'SDN', value: 'Sudan', active: 'A' },
  { code: 'SUR', value: 'Suriname', active: 'A' },
  { code: 'SWZ', value: 'Swaziland', active: 'A' },
  { code: 'SWE', value: 'Sweden', active: 'A' },
  { code: 'CHE', value: 'Switzerland', active: 'A' },
  { code: 'SYR', value: 'Syria (Syrian Arab Republic)', active: 'A' },
  { code: 'TWN', value: 'Taiwan (Province of China)', active: 'A' },
  { code: 'TJK', value: 'Tajikistan', active: 'A' },
  { code: 'TZA', value: 'Tanzania, United Republic of', active: 'A' },
  { code: 'THA', value: 'Thailand', active: 'A' },
  { code: 'TGO', value: 'Togo', active: 'A' },
  { code: 'TKL', value: 'Tokelau', active: 'A' },
  { code: 'TON', value: 'Tonga', active: 'A' },
  { code: 'TTO', value: 'Trinidad and Tobago', active: 'A' },
  { code: 'TUN', value: 'Tunisia', active: 'A' },
  { code: 'TUR', value: 'Turkey', active: 'A' },
  { code: 'TKM', value: 'Turkmenistan', active: 'A' },
  { code: 'TCA', value: 'Turks and Caicos Islands', active: 'A' },
  { code: 'TUV', value: 'Tuvalu', active: 'A' },
  { code: 'UGA', value: 'Uganda', active: 'A' },
  { code: 'UKR', value: 'Ukraine', active: 'A' },
  { code: 'ARE', value: 'United Arab Emirates', active: 'A' },
  { code: 'GBR', value: 'United Kingdom', active: 'A' },
  { code: 'USA', value: 'United States of America', active: 'A' },
  { code: 'URY', value: 'Uruguay', active: 'A' },
  { code: 'UZB', value: 'Uzbekistan', active: 'A' },
  { code: 'VUT', value: 'Vanuatu', active: 'A' },
  { code: 'VEN', value: 'Venezuela', active: 'A' },
  { code: 'VNM', value: 'Viet Nam', active: 'A' },
  { code: 'VGB', value: 'Virgin Islands, British', active: 'A' },
  { code: 'VIR', value: 'Virgin Islands, United States', active: 'A' },
  { code: 'WAL', value: 'Wales', active: 'A' },
  { code: 'WLF', value: 'Wallis and Futuna', active: 'A' },
  { code: 'ESH', value: 'Western Sahara', active: 'A' },
  { code: 'YEM', value: 'Yemen', active: 'A' },
  { code: 'ZMB', value: 'Zambia', active: 'A' },
  { code: 'ZWE', value: 'Zimbabwe', active: 'A' },
  { code: 'XXX', value: 'Unknown/Not Provided', active: 'A' },
  { code: 'YYY', value: 'Other (Not Elsewhere classified)', active: 'A' },
  { code: 'ZZZ', value: 'Not Stated (intentionally)', active: 'S' }
]

export const STATES = [
  { value: 'NSW', text: 'NSW' },
  { value: 'VIC', text: 'VIC' },
  { value: 'QLD', text: 'QLD' },
  { value: 'SA', text: 'SA' },
  { value: 'WA', text: 'WA' },
  { value: 'TAS', text: 'TAS' },
  { value: 'NT', text: 'NT' },
  { value: 'ACT', text: 'ACT' },
  { value: 'OTH', text: 'Other Territories' },
  { value: 'OVS', text: 'Overseas' },
  { value: 'YYY', text: 'Other/Not Applicable' },
  { value: 'XXX', text: 'Unknown/Not Provided' },
  { value: 'ZZZ', text: 'Not Stated (intentionally)' }
]

export const ADMINISTEREDBY = [
  { code: 'SEM', value: 'Emergency Medication', active: 'A' },
  { code: 'SAD', value: 'Self Administered', active: 'A' },
  { code: 'ASU', value: 'Administered with Supervision', active: 'A' },
  { code: 'AAS', value: 'Administered by Authorised Staff', active: 'A' },
  { code: 'TBC', value: 'To be determined', active: 'A' },
  { code: 'ERN', value: 'ERN Unknown', active: 'S' }
]

export const RESIDENCYSTATUS = [
  { code: 'AUC', value: 'Australian Citizen', active: 'A' },
  { code: 'AUCClone', value: 'Australian Citizen Clone', active: 'A' },
  { code: 'AUCC1', value: 'C1 - Australian Citizen', active: 'A' },
  { code: 'AUCC2', value: 'C2 - Australian Citizen', active: 'A' },
  { code: 'NZC', value: 'NZ Citizen', active: 'A' },
  { code: 'PER', value: 'Permanent Resident', active: 'A' },
  { code: 'TMPClon2', value: 'Temporary Visa Holder Clone 2', active: 'A' },
  {
    code: 'TMPClon',
    value: 'Temporary Visa Holder Clone Clone',
    active: 'A'
  },
  { code: 'YYY', value: 'Other (Not elswhere classified)', active: 'S' },
  { code: 'XXX', value: 'Unknown/Not Provided', active: 'S' },
  { code: 'ZZZ', value: 'Not Stated (intentionally)', active: 'S' }
]
