<template>
  <v-container class="studentDetailsWrapper mt-6">
    <div class="subHeader">Details</div>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="studentDetails.familyName"
          label="Family name"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="studentDetails.firstName"
          label="First given name"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="studentDetails.otherName"
          label="Second given name"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="studentDetails.prefFirstName"
          label="Preferred first name"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="gender(studentDetails.genderCode)"
          label="Gender"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="dateOfBirth(studentDetails.dateOfBirth)"
          label="Date of birth"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="intendedStartDate"
          label="Intended start date"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="scholasticYear"
          label="School year"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="disPlaySiblings"
          label="Any siblings enrolled in a NSW government school?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <div v-if="siblings && siblings.length > 0">
      <div class="subHeader">
        {{ SiblingsSubHeading }}
      </div>
      <v-row>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="siblingsSchool"
            label="Name of most recent school"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="gender(filterSibling && filterSibling.genderCode)"
            label="Gender"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="dateOfBirth(filterSibling.dateOfBirth)"
            label="Date of birth"
            placeholder=" "
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="filterSibling.familyName"
            label="Family name"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="filterSibling.firstName"
            label="Given name"
            placeholder=" "
            readonly
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="filterSibling.otherName"
            label="Second given name"
            placeholder=" "
            readonly
          />
        </v-col>
      </v-row>
    </div>
    <div class="subHeader">Aboriginality details</div>
    <v-row>
      <v-col cols="12">
        <AdsTextField
          :value="aboriginality"
          label="Is the student of Aboriginal or Torres Strait Islander origin?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>

    <div class="subHeader">Languages other than English spoken at home</div>
    <v-row>
      <v-col cols="8" md="4">
        <AdsTextField
          :value="displayHomeLangOtherThanEnglish"
          label="Does the student speak a non-English language at home?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="8" md="4" v-if="homeLangOtherThanEnglish">
        <AdsTextField
          :value="studentDetails.mainLanguageOtherThanEnglish"
          label="Main language other than English"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="8" md="4" v-if="homeLangOtherThanEnglish">
        <AdsTextField
          :value="studentDetails.otherLanguage"
          label="Other language spoken at home"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <div v-if="isDisplayStudentMoble" class="subHeader">
      Student's mobile phone
    </div>
    <v-row v-if="isDisplayStudentMoble">
      <v-col cols="8" md="4">
        <AdsTextField
          :value="mobileNumber"
          label="Student mobile number"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <div class="subHeader">Country of birth</div>
    <v-row>
      <v-col cols="8" md="4">
        <AdsTextField
          :value="studentDetails.countryBorn"
          label="Student's country of birth"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <div class="subHeader">Student's residency status</div>
    <v-row>
      <v-col cols="8" md="4">
        <AdsTextField
          :value="residencyStatus"
          label="Status"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="8" md="4" v-if="convertStudentResidency === 'AUCC2'">
        <AdsTextField
          :value="dateReturnAustralia"
          label="Returned to Australia (if living overseas)"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col
        cols="8"
        md="4"
        v-if="
          convertStudentResidency === 'NZC' ||
          convertStudentResidency === 'PER' ||
          convertStudentResidency === 'AUCC1'
        "
      >
        <AdsTextField
          :value="dateArriveAustralia"
          label="Arrival in Australia (if born overseas)"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col
        cols="8"
        md="4"
        v-if="
          convertStudentResidency === 'NZC' || convertStudentResidency === 'PER'
        "
      >
        <AdsTextField
          :value="studentResidency.travelDocumentId"
          label="Passport or travel documentation no."
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="8"
        md="4"
        v-if="
          convertStudentResidency === 'NZC' || convertStudentResidency === 'PER'
        "
      >
        <AdsTextField
          :value="studentResidency.visaIssueCountry"
          label="Country of issue of passport"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col
        cols="8"
        md="4"
        v-if="
          convertStudentResidency === 'NZC' || convertStudentResidency === 'PER'
        "
      >
        <AdsTextField
          :value="visaSubClass"
          label="Current visa subclass"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="8" md="4" v-if="convertStudentResidency === 'NZC'">
        <AdsTextField
          :value="visaExpiryDate"
          label="Visa expiry date"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <div class="subHeader">Previous school</div>
    <v-row>
      <v-col cols="8" md="4">
        <AdsTextField
          :value="prevAttendSchool"
          label="Has student previously attended another school"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="prevSchoolType"
          placeholder=" "
          label="Type of school"
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="prevAttendSchoolName"
          label="Name of school last attended"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <v-row v-if="prevSchoolType">
      <v-col cols="12" md="4">
        <AdsTextField
          :value="schoolLocation"
          label="School location"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="prevSchoolStartDate"
          label="Attended from"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="prevSchoolLastDate"
          label="Attended until"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <div v-if="isKintergarten" class="subHeader">Kindergarten students</div>
    <v-row v-if="isKintergarten">
      <AdsTextField
        :value="displayPreSchools"
        label="Has the student been in early childhood education and care in the year before school?"
        placeholder=" "
        readonly
      />
    </v-row>
    <div v-if="filteredPreschools && filteredPreschools.length > 0">
      <div class="subHeader">Early childhood education and care</div>
      <div
        v-for="(preschool, index) in filteredPreschools"
        :key="index"
        class="studentShadowBox"
      >
        <v-row class="pt-8">
          <v-col cols="12" md="4">
            <AdsTextField
              :value="preschool.preschoolType"
              label="Type"
              placeholder=" "
              readonly
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="preschool.preschoolHoursType"
              label="Hours"
              placeholder=" "
              readonly
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="preschool.postcode"
              label="Postcode"
              placeholder=" "
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="
                getSchoolName(preschool.schoolName, preschool.previousSchoolNm)
              "
              label="Name of preschool/day care service"
              placeholder=" "
              readonly
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { displayDateFormat, formatPhoneNumber } from '@/utils/general'
import {
  SCHOOL_TYPE,
  SCHOLASTIC_YEARS,
  ABORIGINALITY_CODES,
  RESIDENCYSTATUS
} from '@/constants'

export default {
  name: 'StudentDetails',
  components: { AdsTextField },
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['selectedErnStudentInfo']),
    studentDetails() {
      return this.selectedErnStudentInfo?.student || {}
    },
    residencyStatus() {
      return this.selectedErnStudentInfo?.studentResidency?.residencyStatus
    },
    previousSchool() {
      return this.selectedErnStudentInfo?.previousSchool
    },
    siblings() {
      return this.selectedErnStudentInfo?.siblings
    },
    filterSibling() {
      if (this.siblings && this.siblings.length > 0) {
        if (this.siblings.length === 1) {
          return this.siblings[0]
        }

        if (this.siblings.length > 1) {
          const dateOfBirth = this.siblings.map((sibling) =>
            new Date(sibling.dateOfBirth).getTime()
          )
          const oldestDateOfBirth = Math.min(...dateOfBirth)
          const oldestSibling = this.siblings.filter(
            (sibling) =>
              new Date(sibling.dateOfBirth).getTime() == oldestDateOfBirth
          )
          return oldestSibling[0]
        }
      }
      return null
    },
    SiblingsSubHeading() {
      const firstName = this.filterSibling?.firstName
        ? `: ${this.filterSibling?.firstName}`
        : ''
      const familyName = this.filterSibling?.familyName
        ? this.filterSibling?.familyName
        : ''
      return `Siblings ${firstName} ${familyName}`
    },
    disPlaySiblings() {
      return this.siblings && this.siblings.length > 0 ? 'Yes' : 'No'
    },
    homeLangOtherThanEnglish() {
      return this.studentDetails?.homeLangOtherThanEnglish
    },
    displayHomeLangOtherThanEnglish() {
      return this.studentDetails?.homeLangOtherThanEnglish ? 'Yes' : 'No'
    },
    intendedStartDate() {
      return displayDateFormat(
        this.selectedErnStudentInfo?.registration?.intendedStartDate
      )
    },
    scholasticYear() {
      return SCHOLASTIC_YEARS[
        this.selectedErnStudentInfo?.registration?.scholasticYearCode
      ]
    },
    prevAttendSchool() {
      return this.previousSchool ? 'Yes' : 'No'
    },
    prevAttendSchoolName() {
      return this.previousSchool?.prevSchoolName
    },
    prevSchoolType() {
      if (!this.previousSchool?.prevSchoolType) {
        return ''
      }
      const schoolType = SCHOOL_TYPE.find(
        (type) => type.value == this.previousSchool?.prevSchoolType
      )
      return schoolType ? schoolType.text : ''
    },
    schoolLocation() {
      return this.previousSchool?.otherSchoolAttendedLocation
    },
    prevSchoolStartDate() {
      return this.previousSchool?.prevSchoolStartDate
    },
    prevSchoolLastDate() {
      return this.previousSchool?.prevSchoolLastDate
    },
    aboriginality() {
      return ABORIGINALITY_CODES.find(
        (item) => item.code === this.studentDetails?.isAboriTorStraitIslander
      )?.value
    },
    studentResidency() {
      return this.selectedErnStudentInfo?.studentResidency
    },
    dateArriveAustralia() {
      return displayDateFormat(this.studentResidency?.dateArriveAustralia)
    },
    dateReturnAustralia() {
      return displayDateFormat(this.studentResidency?.dateReturnAustralia)
    },
    visaExpiryDate() {
      return displayDateFormat(this.studentResidency?.visaExpiryDate)
    },
    convertStudentResidency() {
      return RESIDENCYSTATUS.find(
        (item) => item.value === this.studentResidency?.residencyStatus
      )?.code
    },
    siblingsSchool() {
      return this.selectedErnStudentInfo?.registration?.orgUnitNm
    },
    mobileNumber() {
      return this.displayFormatPhoneNumber(this.studentDetails?.mobileNumber)
    },
    isDisplayStudentMoble() {
      return this.selectedErnStudentInfo?.registration?.scholasticYearCode > 6
    },
    isKintergarten() {
      return (
        this.selectedErnStudentInfo?.registration?.scholasticYearCode === 'K'
      )
    },
    preschools() {
      return this.selectedErnStudentInfo?.preschools || []
    },
    filteredPreschools() {
      const filteredPreschools = this.preschools.filter((preschool) => {
        return (
          preschool.preschoolHoursType !== 'Unknown/Not Provided' ||
          !!preschool.postcode ||
          !!preschool.schoolName
        )
      })
      return filteredPreschools
    },
    displayPreSchools() {
      return this.preschools && this.preschools.length > 0 ? 'Yes' : 'No'
    },
    visaSubClass() {
      if (this.studentResidency && this.studentResidency.visaSubClassCode) {
        return (
          this.studentResidency.visaSubClassCode +
          '-' +
          this.studentResidency.visaSubClass
        )
      }
      return ''
    }
  },
  methods: {
    gender(genderCode) {
      if (!genderCode) {
        return ''
      }
      return genderCode === 'F' ? 'Female' : 'Male'
    },
    dateOfBirth(dateOfBirth) {
      return displayDateFormat(dateOfBirth)
    },
    displayFormatPhoneNumber(phoneNumber) {
      return formatPhoneNumber(phoneNumber)
    },
    getSchoolName(schoolName, otherSchoolName) {
      return otherSchoolName ? otherSchoolName : schoolName
    }
  }
}
</script>

<style lang="scss" scoped>
.studentShadowBox {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 2rem;
}
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
